@import "normalize.css";

@import "@blueprintjs/core/lib/css/blueprint.css";
/* @import "~@blueprintjs/icons/src/blueprint-icons.scss"; */
/* @import "~@blueprintjs/table/src/table.scss"; */
@import "~@blueprintjs/popover2/lib/css/blueprint-popover2.css";
@import "~@blueprintjs/datetime/lib/css/blueprint-datetime.css";
@import "~@blueprintjs/datetime2/lib/css/blueprint-datetime2.css";
@import "~@blueprintjs/select/lib/css/blueprint-select.css";

#root,
.wrapper,
body,
html {
  min-height: 100%;
}
body {
  position: relative;
  background-color: #f8f8f8;
}
body,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
fieldset {
  margin: 0;
  padding: 0;
  border: 0;
}
.small,
small {
  color: #5c7080;
  font-size: 90%;
}
@media (max-width: 992px) {
  .wrapper {
    word-break: break-all;
  }
}
.wrapper.sticky-nav {
  padding-top: 50px;
}
.wrapper .bp3-fixed-top {
  z-index: 11;
}
.wrapper .app-wrap {
  min-height: calc(100vh - 50px);
}
.bp3-menu{
  background:#fff;border-radius:3px;color:#182026;list-style:none;margin:0;min-width:180px;padding:5px;text-align:left;
}
.bp3-card {
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 0 0 1px rgba(16,22,26,.15), 0 0 0 rgba(16,22,26,0), 0 0 0 rgba(16,22,26,0);
  padding: 20px;
}
.wrapper .app-wrap.app-location-tasks-kanban {
  min-height: calc(100vh - 70px);
}
.wrapper .app-wrap .app-content {
  flex: 1 1;
}
.blank-layout {
  background-color: #fff;
  max-width: 1220px;
  margin: 0 auto;
}
.app-footer {
  display: none;
  position: absolute;
  height: 20px;
  line-height: 20px;
  padding: 0 20px;
  bottom: 0;
  left: 240px;
  right: 0;
  font-size: 0.7rem;
  color: #5c7080;
}
.sidebar-menu {
  overflow: hidden;
  min-height: 100%;
  padding-top: 20px;
  width: 240px;
  background-color: #fff;
  box-shadow: 5px 0 5px -5px rgba(34, 60, 80, 0.2);
  transition: width 0.3s;
}
.sidebar-menu.close {
  width: 60px;
}
.nav-menu {
  margin: 0;
  list-style: none;
  padding: 0;
}
.nav-menu li > a.current {
  background-color: #48aff0;
  color: #fff;
}
.nav-menu li.has-open > a {
  background-color: #d8e1e8;
  color: #394b59;
}
.nav-menu > li {
  position: relative;
}
.nav-menu > li a {
  display: flex;
  align-items: center;
  min-height: 45px;
  padding-left: 25px;
  text-decoration: none;
  color: #394b59;
  transition: all 0.3s;
}
.nav-menu > li a > span {
  margin-right: 20px;
}
.nav-menu > li a:hover {
  -webkit-transform: translateX(5px);
  transform: translateX(5px);
}
.nav-menu > li > .menu-open-btn {
  position: absolute;
  left: 235px;
  top: 22.5px;
  -webkit-transform: translate(-100%, -50%);
  transform: translate(-100%, -50%);
}
.nav-menu > li .sub-menu {
  margin: 0;
  list-style: none;
  padding: 0;
}
.nav-menu > li .sub-menu a:before {
  content: "-";
  margin-right: 15px;
}
.event-bell {
  max-height: calc(100vh - 60px);
  overflow-y: auto;
  max-width: 420px;
}
.navbar-item-inner {
  padding: 10px 0 10px 10px;
}
.sub-header {
  font-size: 11px;
  margin-bottom: 5px;
  text-transform: uppercase;
  font-weight: 700;
  color: #a7b6c2;
}
label > .bp3-text-muted {
  color: #db3737;
}
hr {
  margin: 1px;
  border: none;
  border-bottom: 1px solid #a7b6c2;
}
.p {
  margin-bottom: 15px;
}
.app-widget,
.page {
  padding: 20px;
}
.app-widget {
  position: relative;
}
.app-widget .app-widget-wrap {
  min-height: calc(100vh - 140px);
}
.app-widget .app-widget-wrap .app-widget__left {
  width: 240px;
  border-right: 1px solid #ced9e0;
  transition: width 0.3s;
}
.app-widget .app-widget-wrap .app-widget__left.close {
  width: 0;
  overflow: hidden;
}
.app-widget .app-widget-wrap .app-widget__content {
  flex: 1 1;
}
@media (max-width: 992px) {
  .app-widget {
    padding: 10px;
  }
}
.purchase-items > * {
  align-self: center;
  padding: 5px;
}
.purchase-items .purchase-item__num {
  flex-basis: 5%;
}
.purchase-items .purchase-item__name {
  flex-basis: 30%;
}
.purchase-items .purchase-item__name-2 {
  flex-basis: 45%;
}
.purchase-items .purchase-item__qty {
  flex-basis: 10%;
}
.purchase-items .purchase-item__unit {
  flex-basis: 15%;
}
.purchase-items .purchase-item__supplier {
  flex-basis: 25%;
}
.purchase-items .purchase-item__meta {
  display: flex;
  width: 100%;
  flex-basis: 15%;
  justify-content: space-around;
}
.task-widget .task-widget-item {
  margin-bottom: 15px;
}
.task-widget .task-widget-item.disabled {
  background-color: #fffab7;
}
.task-widget .task-widget-item .task-widget-item__content {
  padding-right: 15px;
  font-size: 15px;
}
.task-widget .task-widget-item .task-widget-p {
  padding-bottom: 15px;
  border-bottom: 1px solid #e1e8ed;
}
.task-widget .task-widget-item .task-widget-item__meta > * {
  vertical-align: middle;
  margin: 5px;
  justify-content: flex-start;
  font-size: 12px;
}
.revision-widget {
  position: relative;
  min-height: 100%;
  overflow: hidden;
}
.sub-drawer .bp3-drawer-header {
  background-color: #e1e8ed;
}
.disable-card {
  position: relative;
}
.disable-card:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  opacity: 0.6;
}
.comment-item {
  padding: 10px;
}
.content-item-from {
  font-weight: 500;
  color: #137cbd;
}
.comment-subitem {
  padding-left: 30px;
  border-top: 1px solid #e1e8ed;
  position: relative;
}
.comment-subitem:before {
  content: "";
  position: absolute;
  left: 10px;
  top: 20px;
  height: 6px;
  width: 6px;
  border-radius: 100%;
  background-color: #ced9e0;
}
.board-widget {
  height: calc(100vh - 70px);
  padding-bottom: 0;
}
.kanban-board {
  padding-top: 30px;
  overflow-y: hidden;
  overflow-x: auto;
  white-space: nowrap;
}
.kanban-board,
.kanban-column {
  position: relative;
  height: 100%;
}
.kanban-column {
  display: inline-block;
  margin: 0 5px;
  width: 280px;
  vertical-align: top;
  border-radius: 5px;
}
.kanban-title {
  position: absolute;
  top: -30px;
  left: 10px;
  font-weight: 500;
  z-index: 9;
}
.kanban-cards {
  height: 100%;
  overflow-y: auto;
  white-space: normal;
}
.kanban-card {
  position: relative;
  margin: 8px;
  padding: 15px;
  border-radius: 3px;
  box-shadow: 0 1px 0 rgba(9, 30, 66, 0.25);
  overflow-x: auto;
  white-space: normal;
  background-color: #eee;
}
.chat-widget .app-widget-wrap {
  height: calc(100vh - 90px);
}
.chat-widget .app-widget-wrap .app-widget__sb {
  min-width: 300px;
  max-width: 400px;
  width: 30%;
  border-right: 1px solid #ced9e0;
}
@media (max-width: 992px) {
  .chat-widget {
    padding: 0;
  }
  .chat-widget .app-widget-wrap {
    height: calc(100vh - 50px);
  }
}
.chat-widget.has_reply .with-editor {
  padding-bottom: 100px;
}
.chat-app-wrapper {
  padding-top: 50px;
  position: relative;
}
.chat-app-wrapper .connection-status {
  position: absolute;
  z-index: 99;
  background-color: #ff7373;
}
.chat-app-wrapper .chat-widget-contact-list {
  height: 100%;
  overflow: auto;
}
.chat-app-wrapper.with-editor {
  padding-bottom: 50px;
}
.chat-app-wrapper .chat-widget-navbar {
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.chat-app-wrapper .chat-widget-thread {
  display: flex;
  flex-direction: column-reverse;
  height: 100%;
  overflow: auto;
  background-color: #ebf1f5;
}
.chat-app-wrapper .chat-widget-editor {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  min-height: 50px;
  background-color: #f5f5f5;
  box-shadow: 0 -4px 3px rgba(0, 0, 0, 0.1);
}
.chat-app-wrapper .chat-widget-editor .chat-widget-reply {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px 15px 15px;
}
.chat-app-wrapper .chat-widget-editor .chat-widget-reply > div {
  display: flex;
  position: relative;
  overflow: hidden;
  max-height: 24px;
  line-height: 24px;
}
.chat-app-wrapper .chat-widget-editor .chat-widget-reply > div:after {
  content: "...";
  background-color: #f5f5f5;
}
.chat-app-wrapper .chat-widget-editor .chat-widget-editor__inner {
  padding: 0 15px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.chat-app-wrapper .chat-widget-editor .chat-widget-editor__inner .chat-editor-emoji {
  position: absolute;
  bottom: 100%;
  background-color: #fff;
  font-size: 18px;
}
.chat-app-wrapper .chat-widget-editor .chat-widget-editor__inner .chat-editor-emoji i {
  display: inline-block;
  margin: 3px;
  font-style: normal;
  cursor: pointer;
}
.chat-list {
  height: 100%;
  overflow-y: auto;
}
.chat-list .chat-list-item {
  cursor: pointer;
  padding: 15px;
  transition: background-color 0.3s;
}
.chat-list .chat-list-item.active,
.chat-list .chat-list-item:hover {
  background-color: #ebf1f5;
}
.chat-list .chat-list-name {
  white-space: nowrap;
  overflow: hidden;
  font-size: 15px;
  font-weight: 500;
}
.thread-list {
  list-style: none;
  margin: 0;
  padding: 0 20px 20px;
}
.thread-list .thread-item {
  display: flex;
}
.thread-list .thread-item .thread-message {
  position: relative;
  max-width: 75%;
  padding: 5px 10px 8px;
  margin: 3px 0;
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 0 1px 0.5px rgba(0, 0, 0, 0.13);
}
.thread-list .thread-item .thread-message .say-icon {
  position: absolute;
  top: -1px;
  color: #fff;
}
.thread-list .thread-item .thread-message .thread-message__reply {
  border-left: 4px solid #2b95d6;
  padding-left: 15px;
  opacity: 0.9;
}
.thread-list .thread-item .thread-message .thread-message__reply .thread-message__msg {
  display: block;
  color: inherit;
  text-decoration: none;
  max-height: 3.9em;
  overflow: hidden;
  margin: 4px 0 10px;
}
.thread-list .thread-item .thread-message .thread-message__sender {
  line-height: 24px;
}
.thread-list .thread-item .thread-message p {
  display: inline;
  margin: 0;
}
.thread-list .thread-item .thread-message > small {
  float: right;
  margin: 5px 8px 0 12px;
}
.thread-list .thread-item .thread-message .view-status {
  float: right;
}
.thread-list .thread-item.left .thread-message {
  border-top-left-radius: 0;
}
.thread-list .thread-item.left .thread-message .say-icon {
  left: -8px;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.thread-list .thread-item.right {
  justify-content: flex-end;
}
.thread-list .thread-item.right .thread-message {
  border-top-right-radius: 0;
  background-color: #b1ecb5;
}
.thread-list .thread-item.right .thread-message .say-icon {
  right: -8px;
  color: #b1ecb5;
}
.site-order-products-table {
  width: 100%;
}
.site-order-products-table th, .site-order-products-table td {
  text-align: left;
}
.site-order-products-table td {
  vertical-align: middle !important;
}
.reasons-list {
  max-height: 200px;
  overflow: auto;
}
.plus-minus-field > input {
  height: 40px;
  max-width: 80px;
}
.hr-divider {
  position: relative;
  width: 100%;
  padding: 20px 0;
  text-align: center;
}
.hr-divider:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  border-bottom: 1px solid #e1e8ed;
}
.hr-divider > span {
  position: relative;
  display: inline-block;
  padding: 0 20px;
  background-color: #f8f8f8;
  z-index: 1;
}
.dropzone {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-radius: 2px;
  border: 2px dashed #ced9e0;
  outline: none;
  cursor: pointer;
  transition: border 0.24s ease-in-out;
}
.dropzone p {
  margin: 0;
  text-align: center;
}
.dropzone:hover {
  border-color: #48aff0;
}
.attachs .attach {
  display: inline-block;
  margin: 5px;
  background-color: #ebf1f5;
  border: 5px solid #ebf1f5;
  text-align: center;
}
.attachs .attach .attach-icon-small {
  vertical-align: top;
  margin-left: -15px;
}
.avatar {
  height: 30px;
  width: 30px;
  min-width: 30px;
  border-radius: 100%;
  background-color: #8a9ba8;
  color: #f5f8fa;
  display: flex;
  align-items: center;
  justify-content: center;
}
.avatar.av-outlined {
  border: 4px solid transparent;
}
.avatar.av-large {
  height: 45px;
  width: 45px;
}
.badge {
  position: absolute;
  display: flex;
  width: 18px;
  height: 18px;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  margin-top: -18px;
  margin-left: -9px;
  font-size: 11px;
  color: #fff;
  background-color: #db3737;
}
ul.list {
  list-style: none;
  margin: 0;
  padding: 0;
}
ul.list li {
  padding: 10px 0;
  border-top: 1px solid #e1e8ed;
}
ul.list li.unaccept {
  border: 5px solid #fd9191 !important;
}
ul.list li.list-item {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  background-color: #fff;
}
ul.list li.list-item.disabled {
  opacity: 0.4;
}
ul.list li.list-item > .flex-grow-1 {
  margin-left: 10px;
}
ul.list li:first-child {
  border-top: none;
}
.ul-item {
  margin-left: 10px;
}
.grid {
  display: flex;
  flex-wrap: wrap;
}
.grid .grid-item {
  flex-basis: 360px;
  min-width: 270px;
  margin: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.infinite-scroll-container {
  position: relative;
  height: 1px;
}
.infinite-scroll-container_spin {
  position: absolute;
  top: 10px;
  left: 0;
  width: 100%;
  z-index: 9999;
}
.editor-toolbar-absolute {
  position: absolute;
  top: -50px;
  background-color: rgba(0, 0, 0, 0.6274509803921569) !important;
}
.editor-wrapper {
  width: 100%;
  border: 3px solid #ebf1f5;
  border-radius: 5px;
  margin-bottom: 5px;
}
.editor-content {
  overflow: visible !important;
  min-height: 150px;
  padding: 0 20px;
  background-color: #fff;
}
.editor-sm.editor-wrapper {
  border: none;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
}
.editor-sm .editor-content {
  min-height: 50px;
  border-radius: 20px;
}
.chat-iemoji-modal {
  top: -175px !important;
}
.rdw-suggestion-dropdown {
  min-width: 220px !important;
  max-height: 250px !important;
  box-shadow: 5px 0 5px -5px rgba(34, 60, 80, 0.2);
}
@media (max-width: 992px) {
  .iemoji-modal {
    margin-left: -230px;
  }
}
.breadcrumb {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.breadcrumb > * {
  margin: 0 5px;
}
.breadcrumb > h3 {
  font-size: 18px;
  font-weight: 500;
}
.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
}
.pagination .pagination-item {
  margin: 3px;
  cursor: pointer;
}
.pagination .pagination-item:hover {
  opacity: 0.8;
}
.pagination .pagination-disabled {
  margin: 3px;
}
.flex-columns {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.flex-columns.columns-2 > * {
  flex-basis: 50%;
}
@media (max-width: 992px) {
  .flex-columns > * {
    flex-basis: 100% !important;
  }
}
.row {
  margin-left: -20px;
  margin-right: -20px;
}
.row > * {
  padding-left: 10px;
  padding-right: 10px;
}
.row > :first-child {
  padding-left: 20px;
}
.row > :last-child {
  padding-right: 20px;
}
@media screen and (max-width: 992px) {
  .row {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .row > * {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
.table-compact-striped {
  width: 100%;
  border-spacing: 0;
}
.table-compact-striped tbody tr:nth-child(odd) td {
  background: rgba(143, 153, 168, 0.15);
}
.table-compact-striped td,
.table-compact-striped th {
  padding: 4px 8px;
  text-align: left;
}
.table-compact-striped.table-nowrap td {
  word-break: break-all;
}
.w-100 {
  width: 100%;
}
.h-100 {
  height: 100%;
}
.mw-100 {
  min-width: 100%;
}
.mh-100 {
  min-height: 100%;
}
.flex-basis-70 {
  flex-basis: 70%;
}
.flex-basis-30 {
  flex-basis: 30%;
}
.position-relative {
  position: relative;
}
.d-flex {
  display: flex !important;
}
.flex-row {
  flex-direction: row !important;
}
.flex-column {
  flex-direction: column !important;
}
.justify-content-start {
  justify-content: flex-start !important;
}
.justify-content-end {
  justify-content: flex-end !important;
}
.justify-content-center {
  justify-content: center !important;
}
.justify-content-between {
  justify-content: space-between !important;
}
.align-items-start {
  align-items: flex-start !important;
}
.align-items-center {
  align-items: center !important;
}
.align-items-end {
  align-items: flex-end !important;
}
.flex-grow-1 {
  flex-grow: 1 !important;
}
.flex-wrap {
  flex-wrap: wrap;
}
.clearflex {
  flex-basis: 100%;
  clear: both;
}
.img-fluid {
  max-width: 100%;
  height: auto;
}
.overflow-hidden {
  overflow: hidden;
}
.nowrap {
  white-space: nowrap;
}
@media (max-width: 992px) {
  .m-direction-column {
    flex-direction: column !important;
  }
}
@media (max-width: 992px) {
  .sm-none {
    display: none !important;
  }
}
@media print {
  .print-none {
    display: none !important;
  }
}

.d-padding {
  padding: 20px;
}
.p-0 {
  padding: 0 !important;
}
.m-0 {
  margin: 0 !important;
}
.box-shadow-none,
.sub-drawer {
  box-shadow: none !important;
}
.text-through {
  text-decoration: line-through;
}
.text-right {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}
.strong {
  font-weight: 700;
}
.white {
  background-color: #fff;
}
.hovered:hover {
  background-color: #ebf1f5;
}
.input-bordered-error {
  border: 1px solid #db3737;
}
.error-bg {
  background-color: #ffb366;
}
.primary-bg {
  background-color: rgba(19, 124, 189, 0.15);
}
.success-bg {
  background-color: rgba(15, 153, 96, 0.15);
}
.warning-bg {
  background-color: rgba(217, 130, 43, 0.15);
}
.danger-bg {
  background-color: rgba(219, 55, 55, 0.15);
}

.m-5{margin:5px!important}.p-5{padding:5px!important}.mt-5{margin-top:5px!important}.pt-5{padding-top:5px!important}.mb-5{margin-bottom:5px!important}.pb-5{padding-bottom:5px!important}.ml-5{margin-left:5px!important}.pl-5{padding-left:5px!important}.mr-5{margin-right:5px!important}.pr-5{padding-right:5px!important}.m-10{margin:10px!important}.p-10{padding:10px!important}.mt-10{margin-top:10px!important}.pt-10{padding-top:10px!important}.mb-10{margin-bottom:10px!important}.pb-10{padding-bottom:10px!important}.ml-10{margin-left:10px!important}.pl-10{padding-left:10px!important}.mr-10{margin-right:10px!important}.pr-10{padding-right:10px!important}.m-15{margin:15px!important}.p-15{padding:15px!important}.mt-15{margin-top:15px!important}.pt-15{padding-top:15px!important}.mb-15{margin-bottom:15px!important}.pb-15{padding-bottom:15px!important}.ml-15{margin-left:15px!important}.pl-15{padding-left:15px!important}.mr-15{margin-right:15px!important}.pr-15{padding-right:15px!important}.m-25{margin:25px!important}.p-25{padding:25px!important}.mt-25{margin-top:25px!important}.pt-25{padding-top:25px!important}.mb-25{margin-bottom:25px!important}.pb-25{padding-bottom:25px!important}.ml-25{margin-left:25px!important}.pl-25{padding-left:25px!important}.mr-25{margin-right:25px!important}.pr-25{padding-right:25px!important}